<template>
  <div :style="slidePosition" class="z-4 w-100 h-100">
    <div class="position-absolute d-flex flex-column align-items-center justify-content-center z-3 spiralWrapper9"
      style=" width: 140vw; height: 140vw; top:60%; left:65%;transform: translate(-50%, -50%); opacity:0;">
      <img src="images/Slide9/Slide9-lines.svg" class="spiral9c w-100 h-100" width="270" height="180"
        aria-label="Yellow Spiral" />
    </div>
    <div class="position-absolute z-2 rounded-circle rotate6a"
      style=" width: 68vw; height: 68vw; top:60%; left:60%;transform: translate(-50%, -50%);">
      <img src="images/Slide6/dottedLine1.svg" class="w-100 h-100" width="233" height="233" aria-label="Yellow Spiral" />
    </div>
    <div class="position-absolute z-2 rounded-circle rotate6b"
      style=" width: 80vw; height: 80vw; top:50%; left:60%;transform: translate(-50%, -50%);">
      <img src="images/Slide6/dottedLine2.svg" class="w-100 h-100" width="233" height="233" aria-label="Yellow Spiral" />
    </div>
    <div
      class="position-absolute d-flex flex-column align-items-start justify-content-center rounded-circle circle9 z-4 border-warning"
      style="background: rgb(0,161,237); width: 55vw; height: 55vw; top:60%; left:65%; padding: 8vw; background: rgb(0,161,237); background: linear-gradient(0deg, rgba(0,161,237,1) 0%, rgba(0,108,169,1) 85%); transform: translate(-50%, -50%) scale(0);border:solid 0.75vh;">
      <h6 class="text-white font-vw-3  fade9" style="opacity:1; ">
        1978
      </h6>
      <h6 class="text-white font-vw-2-5 my-0 fade9" style="opacity:1; ">
        ISOTOPES FOR HEALING
      </h6>
      <p class="mb-0 mt-3 text9 text-white font-vw-1-25" style="opacity:1; ">
        With support from Atomic Energy of Canada Ltd., TRIUMF became home to a
        new cyclotron-based medical isotope production laboratory and developed
        the production techniques needed to create these isotopes for health
        care applications. Medical isotopes, such as strontium-82, which is used
        for high-resolution heart imaging, are a cornerstone of nuclear medicine
        used for imaging and treatment. Today, TRIUMF operates the facility for
        BWX Technologies Inc., supporting the weekly production of 35,000
        patient doses of medical isotopes for use both at home and abroad.
      </p>
    </div>
  </div>
</template>

<script>
import anime from "animejs"
import { slidePositionPlain } from "@/utils/slidePositioning"
export default {
  data() {
    return {}
  },
  props: ["globalUnits", "slideIndex"],
  computed: {
    slidePosition() {
      //console.log("SLIDES INDEX", this.slideIndex)
      return slidePositionPlain(this.globalUnits, this.slideIndex)
    },
    innerPosition() {
      return (
        (this.globalUnits - this.slideIndex.start) /
        (this.slideIndex.end - this.slideIndex.start)
      )
    }
  },
  methods: {
    circle9(percentage) {
      let animation = anime.timeline({
        autoplay: false,
        loop: false,
        endDelay: 6000
      })

      animation
        .add({
          targets: ".circle9",
          scale: [0, 1],
          duration: 100,
          delay: (el, i) => 5000 + 30 * i,
          easing: "easeOutCubic"
        })
        .add({
          targets: ".fade9",

          translateY: ["-50%", "0%"],
          opacity: [0, 1],
          duration: 100,
          easing: "easeInOutSine",
          delay: (el, i) => 1000 + 30 * i
        })
        .add({
          targets: ".text9",

          translateY: ["35%", "0%"],
          opacity: [0, 1],
          duration: 100,
          easing: "easeInOutSine"
        })
      animation.seek(
        percentage <= 0.5
          ? animation.duration * (2 * percentage)
          : animation.duration * 1
      )
    },
    spiralWrapperFade(percentage) {
      let animation = anime.timeline({
        autoplay: false,
        loop: false,
        endDelay: 0
      })

      animation
        .add({
          targets: ".spiralWrapper9",
          opacity: [0, 1],
          duration: 600,
          delay: 0,
          easing: "linear"
        })
        .add({
          targets: ".spiralWrapper9",
          opacity: [1, 0],
          duration: 400,
          delay: 0,
          easing: "linear"
        })

      animation.seek(animation.duration * percentage)
    },
    startLoop() {
      anime({
        loop: true,
        targets: ".rotate6a",
        rotate: "-5turn",
        direction: "alternate",
        duration: 80000,
        easing: "linear"
      })
      anime({
        loop: true,
        targets: ".rotate6b",
        rotate: "5turn",
        direction: "alternate",
        duration: 130000,
        easing: "linear"
      })
    }
  },
  mounted() {
    this.startLoop()
  },
  watch: {
    innerPosition() {
      this.spiralWrapperFade(this.innerPosition)
      this.circle9(this.innerPosition)
    }
  }
}
</script>

<style></style>
